import Item from "./item/Item";

import "./Accordion.css";

export default function Accordion({ selectData }) {
  return (
    <div className="game-type-select-accordion">
      {selectData?.btns.map((btnText, index) => (
        <Item
          index={index}
          key={`accordion-item-${index}`}
          btnText={btnText}
          itemData={selectData?.cards[index]}
        />
      ))}
    </div>
  );
}
