import IconYellowRhombus from "../../../../icons/IconYellowRhombus";

import "./Row.css";

export default function Row() {
  return (
    <div className="release-row">
      <IconYellowRhombus />
      <IconYellowRhombus />
      <IconYellowRhombus />
    </div>
  );
}
