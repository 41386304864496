import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../../contexts/LangContext";
import { useAnimation } from "../../../../../contexts/AnimationContext";

import "./Title.css";

export default function Title({ innerText }) {
  const { currentLangId, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <AnimatePresence mode="wait" key="game-type-select-title-animation">
      <h3 className="game-type-select-title montserrat-bold">
        <motion.span
          className="game-type-select-title-text font-jaguar"
          key={`game-type-select-title-${langsHtmlCodes[currentLangId]}`}
          variants={textVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: animDuration }}>
          {innerText}
        </motion.span>
      </h3>
    </AnimatePresence>
  );
}
