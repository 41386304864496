import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";

import "./Subtitle.css";

export default function Subtitle() {
  const { currentLangId, langData, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <AnimatePresence mode="wait" key="join-subtitle-animation">
      <motion.p
        className="join-subtitle montserrat-regular font-white"
        key={`join-subtitle-${langsHtmlCodes[currentLangId]}`}
        variants={textVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: animDuration }}>
        {langData?.joinSubtitle[currentLangId]}
      </motion.p>
    </AnimatePresence>
  );
}
