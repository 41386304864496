import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useLang } from "../contexts/LangContext";
import { usePages } from "../contexts/PagesContext";
import { useAnimation } from "../contexts/AnimationContext";

import "./App.css";

export default function App() {
  const { langLoaded } = useLang();
  const { currentPage, path } = usePages();
  const { animDuration, pagesVariants } = useAnimation();

  useEffect(() => {
    if (langLoaded) {
      setTimeout(() => {
        const preloader = document.querySelector(".preloader");
        if (preloader) {
          preloader.remove();
        }
      }, 500);
    }
  }, [langLoaded]);

  return (
    langLoaded && (
      <AnimatePresence mode="wait" key="pages-animation">
        <motion.div
          className="pages-animation"
          key={`page-${path}`}
          variants={pagesVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: animDuration }}>
          {currentPage}
        </motion.div>
      </AnimatePresence>
    )
  );
}
