import { useState } from "react";

import Btns from "./btns/Btns";
import Body from "./body/Body";

import "./Cards.css";

export default function Cards({ selectData }) {
  const [currentId, setCurrentId] = useState(0);

  return (
    <div className="game-type-select-cards">
      <Btns
        selectData={selectData}
        currentId={currentId}
        setCurrentId={setCurrentId}
      />
      <Body
        currentId={currentId}
        bodyData={selectData?.cards[currentId]}
        special={selectData?.cards[currentId]?.isSpecial}
      />
    </div>
  );
}
