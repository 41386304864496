import { useLang } from "../../../../contexts/LangContext";

import Item from "./Item";
import IconCost from "../../../../icons/IconCost";
import IconInvestments from "../../../../icons/IconInvestments";
import IconLiquidity from "../../../../icons/IconLiquidity";
import IconMarketing from "../../../../icons/IconMarketing";

import "./Items.css";

export default function Items() {
  const { currentLangId, langData } = useLang();

  const items = [
    {
      icon: <IconLiquidity />,
      title: langData?.aboutLiquidityTitle[currentLangId],
      paragraph: langData?.aboutLiquidityDescription[currentLangId],
    },
    {
      icon: <IconInvestments />,
      title: langData?.aboutInvestmentsTitle[currentLangId],
      paragraph: langData?.aboutInvestmentsDescription[currentLangId],
    },
    {
      icon: <IconCost />,
      title: langData?.aboutCostTitle[currentLangId],
      paragraph: langData?.aboutCostDescription[currentLangId],
    },
    {
      icon: <IconMarketing />,
      title: langData?.aboutMarketingTitle[currentLangId],
      paragraph: langData?.aboutMarketingDescription[currentLangId],
    },
  ];

  return (
    <ul className="about-items">
      {items.map((itemData, i) => (
        <Item key={`item-${i}`} itemData={itemData} />
      ))}
    </ul>
  );
}
