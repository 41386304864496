import { useLang } from "../../../contexts/LangContext";

import SectionTitle from "../../sectionTitle/SectionTitle";
import Coin from "./coin/Coin";

import "./Coins.css";

export const coinTypes = { silver: "coin-s", gold: "coin-g", black: "coin-b" };

export default function Coins() {
  const { currentLangId, langData } = useLang();

  const goldCoinItems = [
    {
      text: langData?.coinsRpsgItemText1[currentLangId],
      isInfo: false,
      isColored: false,
    },
    {
      text: langData?.coinsRpsgItemText2[currentLangId],
      isInfo: false,
      isColored: false,
    },
    {
      before: langData?.coinsRpsgItemText3Before[currentLangId],
      after: langData?.coinsRpsgItemText3After[currentLangId],
      colored: langData?.coinsRpsgItemText3Colored[currentLangId],
      isInfo: true,
      isColored: true,
    },
  ];

  const silverCoinItems = [
    {
      text: langData?.coinsRpssItemText1[currentLangId],
      isInfo: false,
      isColored: false,
    },
    {
      text: langData?.coinsRpssItemText2[currentLangId],
      isInfo: false,
      isColored: false,
    },
  ];

  return (
    <section className="coins">
      <SectionTitle innerText={langData?.coinsTitle[currentLangId]} />
      <div className="coins-container">
        <Coin type={coinTypes.gold} coinItems={goldCoinItems} />
        <Coin type={coinTypes.silver} coinItems={silverCoinItems} />
      </div>
    </section>
  );
}
