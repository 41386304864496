// import { useLang } from "../../../contexts/LangContext";
import { useWidth } from "../../../contexts/WidthContext";

// import Link from "../../link/Link";
import Rights from "./rights/Rights";

import "./Footer.css";

// const linkUrl = "https://www.example.com/";
const mobileWidth = 640; // px

export default function Footer() {
  // const { langData, currentLangId } = useLang();
  const { windowWidth } = useWidth();

  return (
    <section className="footer">
      {windowWidth > mobileWidth ? (
        <>
          <Rights />
          {/* <Link
            withIcon={false}
            withLine={false}
            url={linkUrl}
            innerText={langData?.footerPolicy[currentLangId]}
          />
          <Link
            withIcon={false}
            withLine={false}
            url={linkUrl}
            innerText={langData?.footerTerms[currentLangId]}
          /> */}
        </>
      ) : (
        <>
          {/* <div className="footer-links">
            <Link
              withIcon={false}
              withLine={false}
              url={linkUrl}
              innerText={langData?.footerPolicy[currentLangId]}
            />
            <Link
              withIcon={false}
              withLine={false}
              url={linkUrl}
              innerText={langData?.footerTerms[currentLangId]}
            />
          </div> */}
          <Rights />
        </>
      )}
    </section>
  );
}
