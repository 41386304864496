import { AnimatePresence, motion } from "framer-motion";
import { useAnimation } from "../../../../../../../contexts/AnimationContext";
import { useLang } from "../../../../../../../contexts/LangContext";

import IconOrangeRhombus from "../../../../../../../icons/IconOrangeRhombus";
import AccordionDetails from "@mui/material/AccordionDetails";

import "./Body.css";

export default function Body({ bodyData }) {
  const { textVariants, animDuration } = useAnimation();
  const { currentLangId, langsHtmlCodes } = useLang();

  return (
    <AccordionDetails className="game-type-select-accordion-item-body">
      <div className="game-type-select-accordion-topography">
        <AnimatePresence mode="wait" key="accordion-title-animation">
          <motion.h4
            className="game-type-select-accordion-title font-white montserrat-medium"
            key={`accordion-title-${langsHtmlCodes[currentLangId]}`}
            variants={textVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: animDuration }}>
            {bodyData?.title}
          </motion.h4>
        </AnimatePresence>
        {bodyData?.isSpecial && (
          <div className="game-type-select-accordion-info">
            <AnimatePresence mode="wait" key="accordion-info-title-animation">
              <motion.h5
                className="game-type-select-accordion-info-title font-yellow montserrat-semi-bold"
                key={`accordion-info-title-${langsHtmlCodes[currentLangId]}`}
                variants={textVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: animDuration }}>
                {bodyData?.special?.title}
              </motion.h5>
            </AnimatePresence>
            <AnimatePresence mode="wait" key="accordion-info-text-animation">
              <motion.p
                className="game-type-select-accordion-info-text font-white montserrat-medium"
                key={`accordion-info-text-${langsHtmlCodes[currentLangId]}`}
                variants={textVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: animDuration }}>
                {bodyData?.special?.text}
              </motion.p>
            </AnimatePresence>
          </div>
        )}
        <AnimatePresence mode="wait" key="accordion-paragraph-animation">
          <motion.p
            className="game-type-select-accordion-paragraph font-white montserrat-regular"
            key={`accordion-paragraph-${langsHtmlCodes[currentLangId]}`}
            variants={textVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: animDuration }}>
            {bodyData?.paragraph}
          </motion.p>
        </AnimatePresence>
        <ul className="game-type-select-accordion-items">
          {bodyData?.items.map((itemText, i) => (
            <li
              key={`item-text-${i}`}
              className="game-type-select-accordion-body-item">
              <IconOrangeRhombus />
              <AnimatePresence mode="wait" key="accordion-item-text-animation">
                <motion.span
                  className="game-type-select-accordion-item-text font-white montserrat-regular"
                  key={`accordion-item-text-${langsHtmlCodes[currentLangId]}-${i}`}
                  variants={textVariants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{ duration: animDuration }}>
                  {itemText}
                </motion.span>
              </AnimatePresence>
            </li>
          ))}
        </ul>
      </div>
      {bodyData?.img}
    </AccordionDetails>
  );
}
