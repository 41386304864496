import { useLang } from "../../../contexts/LangContext";

import SectionTitle from "../../sectionTitle/SectionTitle";
import GameTypeSelect from "./gameTypeSelect/GameTypeSelect";
import HandOneOnOne from "../../../hands/HandOneOnOne";
import HandMultiTable from "../../../hands/HandMultiTable";

import oneOnOneOneVictory from "../../../imgs/oneOnOneOneVictory.jpg";
import oneOnOneOneVictoryDrawriskwin from "../../../imgs/oneOnOneOneVictoryDrawriskwin.jpg";
import oneOnOneThreeVictories from "../../../imgs/oneOnOneThreeVictories.jpg";
import oneOnOneThreeVictoriesDrawriskwin from "../../../imgs/oneOnOneThreeVictoriesDrawriskwin.jpg";

import multitableOneVictory from "../../../imgs/multitableOneVictory.jpg";
import multitableOneVictoryDrawriskwin from "../../../imgs/multitableOneVictoryDrawriskwin.jpg";
import multitableThreeVictories from "../../../imgs/multitableThreeVictories.jpg";
import multitableThreeVictoriesDrawriskwin from "../../../imgs/multitableThreeVictoriesDrawriskwin.jpg";

import "./GameTypes.css";

const imgClass = "game-type-select-img";

export default function GameTypes() {
  const { currentLangId, langData } = useLang();

  const btns = [
    langData?.oneVictoryBtn[currentLangId],
    langData?.oneVictoryDrwBtn[currentLangId],
    langData?.threeVictoryBtn[currentLangId],
    langData?.threeVictoryDrwBtn[currentLangId],
  ];

  const oneOnOneSelectData = {
    title: langData?.oneOnOneTitle[currentLangId],
    btns,
    cards: [
      {
        title: langData?.oneVictoryTitle[currentLangId],
        paragraph: langData?.oneVictoryOneOnOnePar[currentLangId],
        isSpecial: false,
        items: [
          langData?.oneVictoryOneOnOneItem1[currentLangId],
          langData?.oneVictoryOneOnOneItem2[currentLangId],
          langData?.oneVictoryOneOnOneItem3[currentLangId],
        ],
        img: <img alt="..." src={oneOnOneOneVictory} className={imgClass} />,
      },
      {
        title: langData?.oneVictoryDrwTitle[currentLangId],
        paragraph: langData?.oneVictoryDrwOneOnOnePar[currentLangId],
        isSpecial: true,
        special: {
          title: langData?.infoTitle[currentLangId],
          text: langData?.infoText[currentLangId],
        },
        items: [
          langData?.oneVictoryDrwOneOnOneItem1[currentLangId],
          langData?.oneVictoryDrwOneOnOneItem2[currentLangId],
          langData?.oneVictoryDrwOneOnOneItem3[currentLangId],
          langData?.oneVictoryDrwOneOnOneItem4[currentLangId],
        ],
        img: (
          <img
            alt="..."
            src={oneOnOneOneVictoryDrawriskwin}
            className={imgClass}
          />
        ),
      },
      {
        title: langData?.threeVictoryTitle[currentLangId],
        paragraph: langData?.threeVictoryOneOnOnePar[currentLangId],
        isSpecial: false,
        items: [
          langData?.threeVictoryOneOnOneItem1[currentLangId],
          langData?.threeVictoryOneOnOneItem2[currentLangId],
          langData?.threeVictoryOneOnOneItem3[currentLangId],
        ],
        img: (
          <img alt="..." src={oneOnOneThreeVictories} className={imgClass} />
        ),
      },
      {
        title: langData?.threeVictoryDrwTitle[currentLangId],
        paragraph: langData?.threeVictoryDrwOneOnOnePar[currentLangId],
        isSpecial: true,
        special: {
          title: langData?.infoTitle[currentLangId],
          text: langData?.infoText[currentLangId],
        },
        items: [
          langData?.threeVictoryDrwOneOnOneItem1[currentLangId],
          langData?.threeVictoryDrwOneOnOneItem2[currentLangId],
          langData?.threeVictoryDrwOneOnOneItem3[currentLangId],
          langData?.threeVictoryDrwOneOnOneItem4[currentLangId],
        ],
        img: (
          <img
            alt="..."
            src={oneOnOneThreeVictoriesDrawriskwin}
            className={imgClass}
          />
        ),
      },
    ],
  };

  const multiWinSelectData = {
    title: langData?.multiWinTitle[currentLangId],
    btns,
    cards: [
      {
        title: langData?.oneVictoryTitle[currentLangId],
        paragraph: langData?.oneVictoryMultiTablePar[currentLangId],
        isSpecial: false,
        items: [
          langData?.oneVictoryMultiTableItem1[currentLangId],
          langData?.oneVictoryMultiTableItem2[currentLangId],
          langData?.oneVictoryMultiTableItem3[currentLangId],
        ],
        img: <img alt="..." src={multitableOneVictory} className={imgClass} />,
      },
      {
        title: langData?.oneVictoryDrwTitle[currentLangId],
        paragraph: langData?.oneVictoryDrwMultiTablePar[currentLangId],
        isSpecial: true,
        special: {
          title: langData?.infoTitle[currentLangId],
          text: langData?.infoText[currentLangId],
        },
        items: [
          langData?.oneVictoryDrwMultiTableItem1[currentLangId],
          langData?.oneVictoryDrwMultiTableItem2[currentLangId],
          langData?.oneVictoryDrwMultiTableItem3[currentLangId],
          langData?.oneVictoryDrwMultiTableItem4[currentLangId],
        ],
        img: (
          <img
            alt="..."
            src={multitableOneVictoryDrawriskwin}
            className={imgClass}
          />
        ),
      },
      {
        title: langData?.threeVictoryTitle[currentLangId],
        paragraph: langData?.threeVictoryMultiTablePar[currentLangId],
        isSpecial: false,
        items: [
          langData?.threeVictoryMultiTableItem1[currentLangId],
          langData?.threeVictoryMultiTableItem2[currentLangId],
          langData?.threeVictoryMultiTableItem3[currentLangId],
        ],
        img: (
          <img alt="..." src={multitableThreeVictories} className={imgClass} />
        ),
      },
      {
        title: langData?.threeVictoryDrwTitle[currentLangId],
        paragraph: langData?.threeVictoryDrwMultiTablePar[currentLangId],
        isSpecial: true,
        special: {
          title: langData?.infoTitle[currentLangId],
          text: langData?.infoText[currentLangId],
        },
        items: [
          langData?.threeVictoryDrwMultiTableItem1[currentLangId],
          langData?.threeVictoryDrwMultiTableItem2[currentLangId],
          langData?.threeVictoryDrwMultiTableItem3[currentLangId],
          langData?.threeVictoryDrwMultiTableItem4[currentLangId],
        ],
        img: (
          <img
            alt="..."
            src={multitableThreeVictoriesDrawriskwin}
            className={imgClass}
          />
        ),
      },
    ],
  };

  return (
    <section className="game-types">
      <SectionTitle innerText={langData?.gameTypesTitle[currentLangId]} />
      <GameTypeSelect icon={<HandOneOnOne />} selectData={oneOnOneSelectData} />
      <GameTypeSelect
        icon={<HandMultiTable />}
        selectData={multiWinSelectData}
      />
    </section>
  );
}
