import {
  createContext,
  useState,
  useLayoutEffect,
  useContext,
  useEffect,
} from "react";
const apiKey = "AIzaSyApYWxk-KzizoOgubVi9cMnnAJQ5txAQ5E";
const sheetId = "1ISFJdNFjMDD_swNhyoUbvI_mFPtLp8CyQ8w_lmQoWdo";
const sheetName = "localization";
const langsShortLabels = ["УКР", "ENG", "РУС", "中文", "ESP ", "DEU"];
const langsFullLabels = [
  "Українська",
  "English",
  "Русский",
  "简体中文",
  "Español",
  "Deutsch",
];
const langsHtmlCodes = ["uk", "en", "ru", "zh", "es", "de"];

export const LangContext = createContext(null);
export const useLang = () => useContext(LangContext);

export function LangProvider({ children }) {
  const [langData, setLang] = useState({});
  const [langLoaded, setLangLoaded] = useState(false);
  const [currentLangId, setCurrentLangId] = useState(setDefaultLangId());

  useEffect(() => {
    document.documentElement.lang = langsHtmlCodes[currentLangId];
  }, [currentLangId]);

  useLayoutEffect(() => {
    fetchSheetData(sheetId, sheetName, apiKey).then((data) => {
      if (data) {
        setLang(data);
        setLangLoaded(true);
      }
    });

    return () => {
      fetchSheetData(sheetId, sheetName, apiKey);
    };
  }, []);

  const value = {
    langData,
    currentLangId,
    langLoaded,
    langsShortLabels,
    langsFullLabels,
    langsHtmlCodes,
    setCurrentLangId,
  };

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
}

function setDefaultLangId() {
  const userLangName =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  const userLangId = langsHtmlCodes.findIndex((htmlCode) =>
    userLangName.includes(htmlCode)
  );
  const errorIndex = -1;
  const defaultLangId = 1;
  const langId = userLangId === errorIndex ? defaultLangId : userLangId;
  document.documentElement.lang = langsHtmlCodes[langId];

  return langId;
}

async function fetchSheetData(sheetId, sheetName, apiKey) {
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${apiKey}`;

  const response = await fetch(url);
  const data = await response.json();
  const rows = data.values;

  const translations = rows.slice(1);

  const result = {};
  translations.forEach((row) => {
    const key = row[0];
    const values = row.slice(1);
    result[key] = values;
  });

  return result;
}
