// import { usePages } from "../../contexts/PagesContext";

import Header from "../../ui/header/Header";
import Frame from "../../ui/frame/Frame";
import Hero from "../../ui/sections/hero/Hero";
import Release from "../../ui/sections/release/Release";
import Statistics from "../../ui/sections/statistics/Statistics";
import GameTypes from "../../ui/sections/gameTypes/GameTypes";
import Footer from "../../ui/sections/footer/Footer";
import Join from "../../ui/sections/join/Join";
import Tables from "../../ui/sections/tables/Tables";
import Combinations from "../../ui/sections/сombinations/Combinations";
import Coins from "../../ui/sections/coins/Coins";
// import Tutorial from "../../ui/sections/tutorial/Tutorial";

export default function Landing() {
  // const { changePage, pages } = usePages();

  return (
    <>
      <Frame />
      <Header />
      <main className="main">
        <Hero />
        <Release />
        <Statistics />
        <GameTypes />
        <Tables />
        <Combinations />
        <Coins />
        {/* <Tutorial /> */}
        <Join />
        <Footer />
      </main>
    </>
  );
}

/* <button
  type="button"
  style={{ padding: "12px" }}
  onClick={() => {
    changePage(pages.cryptoLanding.path);
  }}>
  Toggle page!
</button>; */
