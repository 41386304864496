import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";

import "./css/reset.css";
import "./css/colors.css";
import "./css/fonts.css";
import "./css/btns.css";

import { LangProvider } from "./contexts/LangContext";
import { AnimationProvider } from "./contexts/AnimationContext";
import { WidthProvider } from "./contexts/WidthContext";
import { StatisticsProvider } from "./contexts/StatisticsContext";
import { PagesProvider } from "./contexts/PagesContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PagesProvider>
      <LangProvider>
        <AnimationProvider>
          <WidthProvider>
            <StatisticsProvider>
              <App />
            </StatisticsProvider>
          </WidthProvider>
        </AnimationProvider>
      </LangProvider>
    </PagesProvider>
  </React.StrictMode>
);
