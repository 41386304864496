import FrameRR from "../../frames/FrameRR";
import FrameRB from "../../frames/FrameRB";
import FrameLL from "../../frames/FrameLL";
import FrameLT from "../../frames/FrameLT";

import "./Frame.css";

export default function Frame() {
  return (
    <>
      <FrameLT />
      <FrameLL />
      <FrameRR />
      <FrameRB />
    </>
  );
}
