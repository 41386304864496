import SlotCounter from "react-slot-counter";
import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";

import "./Card.css";

export default function Card({ title, description, icon }) {
  const { currentLangId, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <div className="statistics-card">
      {icon && icon}
      <div className="statistics-card-numder font-white montserrat-bold">
        <SlotCounter value={title} />
        <span className="statistics-card-plus">+</span>
      </div>
      <AnimatePresence mode="wait" key="statistics-card-description-animation">
        <motion.p
          className="statistics-card-description font-white montserrat-medium"
          key={`statistics-card-description-${langsHtmlCodes[currentLangId]}`}
          variants={textVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: animDuration }}>
          {description}
        </motion.p>
      </AnimatePresence>
    </div>
  );
}
