export default function IconOrangeRhombus() {
  return (
    <svg
      className="icon-orange-rhombus"
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M7.4359 0L14.8718 10L7.4359 20L0 10L7.4359 0Z" fill="#A85D13" />
    </svg>
  );
}
