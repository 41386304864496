import { createContext, useContext, useState, useLayoutEffect } from "react";

import Landing from "../pages/landing/Landing";
import CryptoLanding from "../pages/cryptoLanding/CryptoLanding";

export const PagesContext = createContext(null);
export const usePages = () => useContext(PagesContext);

const pages = {
  landing: { path: "/", component: <Landing /> },
  cryptoLanding: { path: "/crypto-landing", component: <CryptoLanding /> },
};

function initPage(pagePath, setPage, setPath) {
  const rootElement = document.getElementById("body");

  switch (true) {
    // case pagePath === pages.cryptoLanding.path:
    //   setPage(pages.cryptoLanding.component);
    //   setPath(pagePath);
    //   rootElement.classList.add("crypto");
    //   break;

    default:
      setPage(pages.landing.component);
      setPath(pagePath);
      rootElement.classList.remove("crypto");
      break;
  }
}

export function PagesProvider({ children }) {
  const [currentPage, setCurrentPage] = useState(pages.landing.component);
  const [path, setPath] = useState(pages.landing.path);

  useLayoutEffect(() => {
    initPage(window.location.pathname, setCurrentPage, setPath);
  }, []);

  useLayoutEffect(() => {
    const handleLocationChange = () =>
      initPage(window.location.pathname, setCurrentPage, setPath);

    window.addEventListener("popstate", handleLocationChange);
    return () => window.removeEventListener("popstate", handleLocationChange);
  }, []);

  function changePage(newPath) {
    window.history.pushState({}, "", newPath);
    initPage(newPath, setCurrentPage, setPath);
  }

  const value = { currentPage, changePage, pages, path };

  return (
    <PagesContext.Provider value={value}>{children}</PagesContext.Provider>
  );
}
