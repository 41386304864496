import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import { useLang } from "../../../../../../contexts/LangContext";
import { useAnimation } from "../../../../../../contexts/AnimationContext";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Body from "./body/Body";

import "./Item.css";

const wrapClass = "game-type-select-accordion-item";
const btnClass = "game-type-select-accordion-btn font-white montserrat-regular";

export default function Item({ itemData, btnText, index }) {
  const { langsHtmlCodes, currentLangId } = useLang();
  const { textVariants, animDuration } = useAnimation();
  const accordionItem = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setTimeout(() => {
        accordionItem.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 300);
    }
  }

  return (
    <div
      ref={accordionItem}
      className={isOpen ? `${wrapClass} open` : wrapClass}>
      <Accordion>
        <AccordionSummary
          className={isOpen ? `${btnClass} open` : btnClass}
          expandIcon={<ExpandMoreIcon />}
          id="accordion-btn"
          onClick={handleClick}>
          <AnimatePresence mode="wait" key="accordion-btn-text-animation">
            <motion.span
              className="game-type-select-accordion-btn-text font-white montserrat-regular"
              key={`accordion-btn-text-${langsHtmlCodes[currentLangId]}`}
              variants={textVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: animDuration }}>
              {btnText}
            </motion.span>
          </AnimatePresence>
        </AccordionSummary>
        <Body index={index} bodyData={itemData} />
      </Accordion>
    </div>
  );
}
