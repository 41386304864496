import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";

import "./Cards.css";

export default function Card({ title, text, icon }) {
  const { currentLangId, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <div className="tables-card">
      <div className="tables-card-topography">
        <AnimatePresence mode="wait" key="tables-card-title-animation">
          <motion.h5
            className="tables-card-title montserrat-bold font-yellow"
            key={`tables-card-title-${langsHtmlCodes[currentLangId]}`}
            variants={textVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: animDuration }}>
            {title.toUpperCase()}
          </motion.h5>
        </AnimatePresence>
        <AnimatePresence mode="wait" key="tables-card-text-animation">
          <motion.p
            className="tables-card-text montserrat-regular font-white"
            key={`tables-card-text-${langsHtmlCodes[currentLangId]}`}
            variants={textVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: animDuration }}>
            {text}
          </motion.p>
        </AnimatePresence>
      </div>
      {icon}
    </div>
  );
}
