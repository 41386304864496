export default function FrameRB() {
  return (
    <svg
      className="frame-rb"
      width="308"
      height="23"
      viewBox="0 0 308 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_615_355)">
        <path
          d="M269.347 -19C264.992 -7.68236 249.022 1.67647 241.582 4.94117L237.77 8.20588C221.347 9.92891 185.07 11.6882 171.351 4.94117C154.202 -3.49266 121.809 4.94116 106.021 1.67645C93.39 -0.935312 76.0775 11.4706 69 18L308 8.75L308 -204C304.552 -191.123 297.329 -162.865 296.023 -152.853C294.39 -140.338 286.224 -85.3823 286.769 -66.3382C287.313 -47.2941 274.791 -33.1471 269.347 -19Z"
          fill="#F5BE38"
        />
        <path
          d="M308 23L0 23C19.2157 19.9076 59.1843 13.6138 65.3333 13.1773C73.0196 12.6316 117.5 4.99998 140 8.81162C157.863 11.8377 199.517 10.8672 214.5 3.99999C226.5 -1.50001 239.435 1.71745 264.078 1.71745C279.538 1.71745 288.784 -20.1108 290.98 -22.8393C293.176 -25.5679 300.314 -62.1302 300.314 -65.9501C300.314 -69.7701 298.118 -113.972 300.314 -127.069C302.071 -137.547 306.17 -162.722 308 -174L308 23Z"
          fill="#A85D13"
        />
      </g>
      <defs>
        <clipPath id="clip0_615_355">
          <rect
            width="308"
            height="23"
            fill="white"
            transform="translate(308 23) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
