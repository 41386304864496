import Paragraph from "./paragraph/Paragraph";
import Row from "./row/Row";
import Card from "./card/Card";

import "./Release.css";

export default function Release() {
  return (
    <section className="release">
      <Row />
      <Paragraph />
      <Card />
    </section>
  );
}
