export default function IconYellowRhombus() {
  return (
    <svg
      className="icon-yellow-rhombus"
      width="29"
      height="39"
      viewBox="0 0 29 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 0L29 19.5L14.5 39L0 19.5L14.5 0Z" fill="#F5BE38" />
    </svg>
  );
}
