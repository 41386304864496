export default function IconWhiteInfo() {
  return (
    <svg
      className="icon-white-info"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 7.06738H9V5.06738H11M11 15.0674H9V9.06738H11M10 0.0673828C8.68678 0.0673828 7.38642 0.32604 6.17316 0.828587C4.95991 1.33113 3.85752 2.06773 2.92893 2.99631C1.05357 4.87168 0 7.41522 0 10.0674C0 12.7195 1.05357 15.2631 2.92893 17.1384C3.85752 18.067 4.95991 18.8036 6.17316 19.3062C7.38642 19.8087 8.68678 20.0674 10 20.0674C12.6522 20.0674 15.1957 19.0138 17.0711 17.1384C18.9464 15.2631 20 12.7195 20 10.0674C20 8.75416 19.7413 7.4538 19.2388 6.24055C18.7362 5.02729 17.9997 3.9249 17.0711 2.99631C16.1425 2.06773 15.0401 1.33113 13.8268 0.828587C12.6136 0.32604 11.3132 0.0673828 10 0.0673828Z"
        fill="white"
      />
    </svg>
  );
}
