import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";

import "./Btn.css";

const linkUrl = "https://t.me/macsoft_rps_game_bot";

export default function Btn() {
  const { currentLangId, langData, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <div className="hero-btn-wrap">
      <a
        href={linkUrl}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="hero-btn gold-btn">
        <AnimatePresence mode="wait" key="hero-btn-animation">
          <motion.div
            className="btn-inner"
            key={`hero-btn-text-${langsHtmlCodes[currentLangId]}`}
            variants={textVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: animDuration }}>
            {langData?.heroBtn[currentLangId]}
          </motion.div>
        </AnimatePresence>
      </a>
    </div>
  );
}
