import { useState } from "react";

import Btn from "./btn/Btn";
import List from "./list/List";

import "./LangSelect.css";

export default function LangSelect() {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  function toggleSelect() {
    setIsSelectOpen(!isSelectOpen);
  }

  return (
    <div className={isSelectOpen ? "lang-select open" : "lang-select"}>
      <Btn toggleSelect={toggleSelect} />
      <List toggleSelect={toggleSelect} isSelectOpen={isSelectOpen} />
    </div>
  );
}
