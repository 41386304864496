import "./Arrow.css";

export default function Arrow() {
  return (
    <div className="lang-select-arrow">
      <div className="lang-select-arrow-left-line"></div>
      <div className="lang-select-arrow-right-line"></div>
    </div>
  );
}
