import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../contexts/LangContext";
import { useAnimation } from "../../../contexts/AnimationContext";

export default function ShortLabel() {
  const { langsShortLabels, currentLangId, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <AnimatePresence mode="wait" key="short-label-animation" >
      <motion.p
        className="lang-select-font montserrat-medium font-white"
        key={`short-label-${langsHtmlCodes[currentLangId]}`}
        variants={textVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: animDuration }}
        style={{ marginRight: "8px" }}>
        {langsShortLabels[currentLangId]}
      </motion.p>
    </AnimatePresence>
  );
}
