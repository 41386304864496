import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";

import Link from "../../../link/Link";
import IconCalendar from "../../../../icons/IconCalendar";
import IconTelegram from "../../../../icons/IconTelegram";

import "./Card.css";

const linkUrl = "https://t.me/rpsgameua";

export default function Card() {
  const { currentLangId, langData, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <div className="release-card">
      <IconCalendar />
      <div className="release-card-body">
        <AnimatePresence mode="wait" key="release-card-title-animation">
          <motion.h5
            className="release-card-title font-white montserrat-medium"
            key={`release-card-title-${langsHtmlCodes[currentLangId]}`}
            variants={textVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: animDuration }}>
            {langData?.releaseCardTitle[currentLangId]}
          </motion.h5>
        </AnimatePresence>
        <Link
          withIcon={true}
          withLine={true}
          innerText={langData?.releaseLink[currentLangId]}
          url={linkUrl}
          icon={<IconTelegram />}
        />
      </div>
    </div>
  );
}
