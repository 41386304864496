import { useLang } from "../../../contexts/LangContext";

import SectionTitle from "../../sectionTitle/SectionTitle";
import Subtitle from "./subtitle/Subtitle";
import Cards from "./cards/Cards";

import "./Tables.css";

export default function Tables() {
  const { currentLangId, langData } = useLang();

  return (
    <section className="tables">
      <SectionTitle innerText={langData?.tablesTitle[currentLangId]} />
      <Subtitle />
      <Cards />
    </section>
  );
}
