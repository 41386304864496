import ShortLabel from "../shortLabel/ShortLabel";
import Arrow from "../arrow/Arrow";
import Line from "../line/Line";

import "./Btn.css";

export default function Btn({ toggleSelect }) {
  return (
    <button
      type="button"
      className="lang-select-btn"
      onClick={() => toggleSelect()}>
      <div className="lang-select-inner">
        <ShortLabel />
        <Arrow />
      </div>
      <Line />
    </button>
  );
}
