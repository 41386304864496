import LangSelect from "../langSelect/LangSelect";
import "./Header.css";

export default function Header() {
  return (
    <header className="header">
      <LangSelect />
    </header>
  );
}
