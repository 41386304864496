import { AnimatePresence, motion } from "framer-motion";
import { useAnimation } from "../../../../../../contexts/AnimationContext";
import { useLang } from "../../../../../../contexts/LangContext";

import IconOrangeRhombus from "../../../../../../icons/IconOrangeRhombus";

import "./Body.css";

export default function Body({ bodyData, special, currentId }) {
  const { textVariants, selectListVariants, animDuration } = useAnimation();
  const { currentLangId, langsHtmlCodes } = useLang();

  return (
    <AnimatePresence mode="wait" key="game-type-select-animation">
      <motion.div
        className="game-type-select-card-body"
        key={`game-type-select-${currentId}`}
        variants={selectListVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: animDuration }}>
        <div className="game-type-select-card-topography">
          <AnimatePresence mode="wait" key="card-title-animation">
            <motion.h4
              className="game-type-select-card-title font-white montserrat-medium"
              key={`card-title-${langsHtmlCodes[currentLangId]}`}
              variants={textVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: animDuration }}>
              {bodyData?.title}
            </motion.h4>
          </AnimatePresence>
          {special && (
            <div className="game-type-select-card-info">
              <AnimatePresence mode="wait" key="card-info-title-animation">
                <motion.h5
                  className="game-type-select-card-info-title font-yellow montserrat-semi-bold"
                  key={`card-info-title-${langsHtmlCodes[currentLangId]}`}
                  variants={textVariants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{ duration: animDuration }}>
                  {bodyData?.special?.title}
                </motion.h5>
              </AnimatePresence>
              <AnimatePresence mode="wait" key="card-info-text-animation">
                <motion.p
                  className="game-type-select-card-info-text font-white montserrat-medium"
                  key={`card-info-text-${langsHtmlCodes[currentLangId]}`}
                  variants={textVariants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{ duration: animDuration }}>
                  {bodyData?.special?.text}
                </motion.p>
              </AnimatePresence>
            </div>
          )}
          <AnimatePresence mode="wait" key="card-paragraph-animation">
            <motion.p
              className="game-type-select-card-paragraph font-white montserrat-regular"
              key={`card-paragraph-${langsHtmlCodes[currentLangId]}`}
              variants={textVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: animDuration }}>
              {bodyData?.paragraph}
            </motion.p>
          </AnimatePresence>
          <ul className="game-type-select-card-items">
            {bodyData?.items.map((itemText, i) => (
              <li key={`item-text-${i}`} className="game-type-select-card-item">
                <IconOrangeRhombus />
                <AnimatePresence mode="wait" key="card-item-text-animation">
                  <motion.span
                    className="game-type-select-card-item-text font-white montserrat-regular"
                    key={`card-item-text-${langsHtmlCodes[currentLangId]}-${i}`}
                    variants={textVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{ duration: animDuration }}>
                    {itemText}
                  </motion.span>
                </AnimatePresence>
              </li>
            ))}
          </ul>
        </div>
        {bodyData?.img}
      </motion.div>
    </AnimatePresence>
  );
}
