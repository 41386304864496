import { coinTypes } from "../Coins";

import Btn from "../btn/Btn";
import CoinRpsg from "../../../../coins/CoinRpsg";
import CoinRpss from "../../../../coins/CoinRpss";
import Title from "../title/Title";
import Items from "../items/Items";

import "./Coin.css";

export default function Coin({ type, coinItems }) {
  return (
    <div className="coin">
      <div className="coin-body">
        {type === coinTypes.gold && <CoinRpsg />}
        {type === coinTypes.silver && <CoinRpss />}
        <Title type={type} />
        <Items type={type} coinItems={coinItems} />
      </div>
      <Btn type={type} withIcon={type === coinTypes.silver} />
    </div>
  );
}
