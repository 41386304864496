import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";

const preText = "2024 © Rock Paper Scissors";

export default function Rights() {
  const { currentLangId, langData, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <AnimatePresence mode="wait" key="footer-text-animation">
      <motion.p
        className="footer-text montserrat-medium"
        key={`footer-text-${langsHtmlCodes[currentLangId]}`}
        variants={textVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: animDuration }}>
        {`${preText} | ${langData?.footerRights[currentLangId]}`}
      </motion.p>
    </AnimatePresence>
  );
}
