import { useLang } from "../../../../contexts/LangContext";
import { useStatistics } from "../../../../contexts/StatisticsContext";
import { useWidth } from "../../../../contexts/WidthContext";

import Card from "./Card";
import CoinRpsg from "../../../../coins/CoinRpsg";
import CoinRpss from "../../../../coins/CoinRpss";
import PeoplePrivate from "../../../../people/PeoplePrivate";

import "./Card.css";

function numbToString(number) {
  return String(number)
    .split("")
    .reverse()
    .map((letter, i) => (i % 3 === 0 && i !== 0 ? `${letter}.` : letter))
    .reverse()
    .join("");
}

export default function Cards() {
  const { currentLangId, langData } = useLang();
  const { playersCount, paymentCountG, paymentCountS } = useStatistics();
  const { windowWidth, mobileWidth } = useWidth();

  return windowWidth > mobileWidth ? (
    <>
      <div className="statistics-cards">
        <Card
          icon={<CoinRpsg />}
          title={numbToString(paymentCountG)}
          description={langData?.statisticsEarned[currentLangId]}
        />
        <Card
          icon={<CoinRpss />}
          title={numbToString(paymentCountS)}
          description={langData?.statisticsEarned[currentLangId]}
        />
      </div>
      <div className="statistics-cards">
        <Card
          icon={<PeoplePrivate />}
          title={numbToString(playersCount)}
          description={langData?.statisticsPlayers[currentLangId]}
        />
      </div>
    </>
  ) : (
    <div className="statistics-cards">
      <Card
        icon={<CoinRpsg />}
        title={numbToString(paymentCountG)}
        description={langData?.statisticsEarned[currentLangId]}
      />
      <Card
        icon={<CoinRpss />}
        title={numbToString(paymentCountS)}
        description={langData?.statisticsEarned[currentLangId]}
      />
      <Card
        icon={<PeoplePrivate />}
        title={numbToString(playersCount)}
        description={langData?.statisticsPlayers[currentLangId]}
      />
    </div>
  );
}
