export default function FrameRR() {
  return (
    <svg
      className="frame-rr"
      width="58"
      height="204"
      viewBox="0 0 58 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_615_358)">
        <path
          d="M19.3471 185C14.9918 196.318 -0.977858 205.676 -8.41826 208.941L-12.2301 212.206C-28.6534 213.929 -64.9298 215.688 -78.6492 208.941C-95.7984 200.507 -128.191 208.941 -143.979 205.676C-156.61 203.065 -173.923 215.471 -181 222L58 212.75L58 0C54.552 12.8775 47.3294 41.1353 46.0228 51.1471C44.3895 63.6618 36.2241 118.618 36.7685 137.662C37.313 156.706 24.7913 170.853 19.3471 185Z"
          fill="#F5BE38"
        />
        <path
          d="M58 227L-250 227C-230.784 223.908 -190.816 217.614 -184.667 217.177C-176.98 216.632 -132.5 209 -110 212.812C-92.1369 215.838 -50.483 214.867 -35.5 208C-23.5 202.5 -10.5654 205.717 14.0784 205.717C29.5379 205.717 38.7843 183.889 40.9804 181.161C43.1765 178.432 50.3137 141.87 50.3137 138.05C50.3137 134.23 48.1177 90.0277 50.3137 76.9307C52.0706 66.4532 56.17 41.2779 58 30L58 227Z"
          fill="#A85D13"
        />
      </g>
      <defs>
        <clipPath id="clip0_615_358">
          <rect
            width="58"
            height="204"
            fill="white"
            transform="translate(58 204) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
