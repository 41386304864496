import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../contexts/LangContext";
import { useAnimation } from "../../contexts/AnimationContext";

import "./Link.css";

export default function Link({ innerText, icon, url, withIcon, withLine }) {
  const { currentLangId, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer nofollow"
      className="link font-white montserrat-medium">
      <div className="link-body">
        <AnimatePresence mode="wait" key="link-animation">
          <motion.span
            className="link-text"
            key={`link-text-${langsHtmlCodes[currentLangId]}`}
            variants={textVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: animDuration }}>
            {innerText}
          </motion.span>
        </AnimatePresence>
        {withLine && <div className="link-line"></div>}
      </div>
      {withIcon && icon}
    </a>
  );
}
