import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../../../contexts/LangContext";
import { useAnimation } from "../../../../../../contexts/AnimationContext";

import "./Btns.css";

const btnClass = "game-type-select-card-btn font-white montserrat-regular";

export default function Btns({ selectData, currentId, setCurrentId }) {
  const { langsHtmlCodes, currentLangId } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <div className="game-type-select-card-btns">
      {selectData?.btns.map((btnText, i) => (
        <AnimatePresence mode="wait" key={`card-btn-animation-${i}`}>
          <motion.button
            type="button"
            onClick={() => setCurrentId(i)}
            className={currentId === i ? `${btnClass} current` : btnClass}
            key={`card-btn-${i}-${langsHtmlCodes[currentLangId]}`}
            variants={textVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: animDuration }}>
            {btnText}
          </motion.button>
        </AnimatePresence>
      ))}
    </div>
  );
}
