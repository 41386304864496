import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";

import "./Paragraph.css";

export default function Paragraph() {
  const { currentLangId, langData, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <AnimatePresence mode="wait" key="release-paragraph-animation">
      <motion.h5
        className="release-paragraph font-white montserrat-medium"
        key={`release-paragraph-${langsHtmlCodes[currentLangId]}`}
        variants={textVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: animDuration }}>
        {langData?.releaseParagraph[currentLangId]}
      </motion.h5>
    </AnimatePresence>
  );
}
