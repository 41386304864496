import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";
import { coinTypes } from "../Coins";

import IconTelegram from "../../../../icons/IconTelegram";

import "./Btn.css";

const gClass = "gold-btn";
const sClass = "silver-btn";
const gLink = "https://t.me/macsoft_rps_game_bot";
const sLink = "https://t.me/macsoft_rps_game_bot";

export default function Btn({ type, withIcon }) {
  const { currentLangId, langData, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  let btnText, btnClass, linkUrl;

  switch (true) {
    case type === coinTypes.gold:
      btnText = langData?.coinsRpsgBtn[currentLangId];
      btnClass = gClass;
      linkUrl = gLink;
      break;

    case type === coinTypes.silver:
      btnText = langData?.coinsRpssBtn[currentLangId];
      btnClass = sClass;
      linkUrl = sLink;
      break;

    default:
      break;
  }

  return (
    <div className="coin-btn-wrap">
      <a
        href={linkUrl}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className={`coin-btn ${btnClass}`}>
        <AnimatePresence mode="wait" key="coin-btn-animation">
          <motion.div
            className="btn-inner"
            key={`coin-btn-text-${langsHtmlCodes[currentLangId]}`}
            variants={textVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: animDuration }}>
            {btnText}
          </motion.div>
        </AnimatePresence>
        {withIcon && <IconTelegram />}
      </a>
    </div>
  );
}
