import { useLang } from "../../../contexts/LangContext";

import SectionTitle from "../../sectionTitle/SectionTitle";
import Paragraph from "./paragraph/Paragraph";
import Items from "./items/Items";

import "./About.css";

export default function About() {
  const { currentLangId, langData } = useLang();

  return (
    <section className="about">
      <SectionTitle innerText={langData?.aboutTitle[currentLangId]} />
      <Paragraph />
      <Items />
    </section>
  );
}
