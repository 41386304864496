import { useWidth } from "../../../contexts/WidthContext";
// import { useLang } from "../../../contexts/LangContext";

import TopText from "./topText/TopText";
import Title from "./title/Title";
import Btn from "./btn/Btn";
// import Link from "../../link/Link";
import HandSilverPaperRive from "../../../rive/HandSilverPaperRive";
import HandSilverScissorsRive from "../../../rive/HandSilverScissorsRive";
// import IconYouTube from "../../../icons/IconYouTube";

import "./Hero.css";

// const linkUrl = "https://www.example.com/";
const defaultMargin = `0 0`;
const mobileMargin = `0 -24px`;
const tabletMargin = `0 -7.777vw`;
const maxHeroWidth = 1216; // px
const desktopWidth = 1440; // px
const mobileWidth = 640; // px
function setMargin(windowWidth) {
  switch (true) {
    case windowWidth <= mobileWidth:
      return mobileMargin;

    case windowWidth > desktopWidth:
      return `0 -${(windowWidth - maxHeroWidth) / 2}px`;

    case windowWidth > mobileWidth:
      return tabletMargin;

    default:
      return defaultMargin;
  }
}

export default function Hero() {
  const { windowWidth } = useWidth();
  // const { langData, currentLangId } = useLang();

  return (
    <section className="hero">
      <TopText />
      <Title />
      <div className="hero-body" style={{ margin: setMargin(windowWidth) }}>
        {windowWidth > mobileWidth ? (
          <>
            <HandSilverScissorsRive />
            <div className="hero-body-col">
              <Btn />
              {/* <Link
                withIcon={true}
                withLine={true}
                url={linkUrl}
                innerText={langData?.heroLink[currentLangId]}
                icon={<IconYouTube />}
              /> */}
            </div>
            {/* <HandSilverPaper /> */}
            <HandSilverPaperRive />
          </>
        ) : (
          <>
            <div className="hero-body-row" style={{ width: "100vw" }}>
              <HandSilverScissorsRive />
              <HandSilverPaperRive />
            </div>
            <div className="hero-body-col">
              <Btn />
              {/* <Link
                withIcon={true}
                withLine={true}
                url={linkUrl}
                innerText={langData?.heroLink[currentLangId]}
                icon={<IconYouTube />}
              /> */}
            </div>
          </>
        )}
      </div>
    </section>
  );
}
