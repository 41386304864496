import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../contexts/LangContext";
import { useAnimation } from "../../contexts/AnimationContext";

import "./SectionTitle.css";

export default function SectionTitle({ innerText }) {
  const { currentLangId, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <AnimatePresence mode="wait" key="section-title-animation">
      <motion.h2
        className="section-title amatic-sc-bold"
        key={`section-title-${langsHtmlCodes[currentLangId]}`}
        variants={textVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: animDuration }}>
        <span className="section-title-text font-white">{innerText}</span>
      </motion.h2>
    </AnimatePresence>
  );
}
