// import { usePages } from "../../contexts/PagesContext";

import About from "../../ui/sections/about/About";

export default function CryptoLanding() {
  // const { changePage, pages } = usePages();

  return (
    <>
      <main className="main-crypto">
        <About />
      </main>
    </>
  );
}

/* <button
  type="button"
  style={{ padding: "12px" }}
  onClick={() => {
    changePage(pages.landing.path);
  }}>
  Toggle page!
</button>; */
