export default function IconWhiteRhombus() {
  return (
    <svg
      className="icon-white-rhombus"
      width="15"
      height="21"
      viewBox="0 0 15 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.4359 0.126953L14.8718 10.127L7.4359 20.127L0 10.127L7.4359 0.126953Z"
        fill="#E3E3E3"
      />
    </svg>
  );
}
