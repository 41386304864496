import { useLang } from "../../../contexts/LangContext";
import { useWidth } from "../../../contexts/WidthContext";

import SectionTitle from "../../sectionTitle/SectionTitle";
import PersonPaper from "../../../people/PersonPaper";
import PersonPaperLg from "../../../people/PersonPaperLg";
import PersonScissors from "../../../people/PersonScissors";
import PersonScissorsLg from "../../../people/PersonScissorsLg";
import Subtitle from "./subtitle/Subtitle";
import Btn from "./btn/Btn";

import "./Join.css";

const mobileWidth = 640; // px

export default function Join() {
  const { langData, currentLangId } = useLang();
  const { windowWidth } = useWidth();

  return (
    <section className="join">
      <div className="join-front">
        <SectionTitle innerText={langData?.joinTitle[currentLangId]} />
        {windowWidth > mobileWidth ? (
          <div className="join-front-row">
            <PersonScissorsLg />
            <div className="join-front-col">
              <Subtitle />
              <Btn />
            </div>
            <PersonPaperLg />
          </div>
        ) : (
          <>
            <Subtitle />
            <Btn />
          </>
        )}
      </div>
      {windowWidth <= mobileWidth && (
        <div className="join-people">
          <PersonScissors />
          <PersonPaper />
        </div>
      )}
    </section>
  );
}
