export const config = {
  apiKey: "AIzaSyBYch2YjKiIVNjGFybxS5ElOQ34rpvHzzE",
  authDomain: "game-rock-paper-scissors-7a025.firebaseapp.com",
  databaseURL:
    "https://game-rock-paper-scissors-7a025-default-rtdb.firebaseio.com",
  projectId: "game-rock-paper-scissors-7a025",
  storageBucket: "game-rock-paper-scissors-7a025.appspot.com",
  messagingSenderId: "990534694121",
  appId: "1:990534694121:web:905d06c1f7bfc0d6ca2c78",
};
