export default function IconTelegram() {
  return (
    <svg
      className="icon-telegram"
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.0488 6C20.7457 6 15.6551 8.10844 11.9082 11.8578C8.15749 15.6087 6.0499 20.6956 6.04883 26C6.04883 31.3022 8.1582 36.3928 11.9082 40.1422C15.6551 43.8916 20.7457 46 26.0488 46C31.352 46 36.4426 43.8916 40.1895 40.1422C43.9395 36.3928 46.0488 31.3022 46.0488 26C46.0488 20.6978 43.9395 15.6072 40.1895 11.8578C36.4426 8.10844 31.352 6 26.0488 6Z"
        fill="url(#paint0_linear_743_50)"
      />
      <path
        d="M15.1022 25.7886C20.9335 23.2486 24.821 21.574 26.7647 20.7648C32.321 18.4545 33.4741 18.0533 34.2272 18.0397C34.3928 18.037 34.7616 18.078 35.0022 18.2725C35.2022 18.4365 35.2585 18.6584 35.2866 18.8142C35.3116 18.9698 35.346 19.3245 35.3179 19.6014C35.0179 22.7639 33.7147 30.4383 33.0522 33.9804C32.7741 35.4792 32.221 35.9817 31.6866 36.0308C30.5241 36.1376 29.6429 35.2633 28.5179 34.5261C26.7585 33.372 25.7647 32.6539 24.0554 31.5279C22.0804 30.2267 23.3616 29.5114 24.4866 28.3426C24.7804 28.0367 29.8991 23.382 29.996 22.9598C30.0085 22.907 30.021 22.7101 29.9022 22.6064C29.7866 22.5023 29.6147 22.538 29.4897 22.5661C29.3116 22.6061 26.5022 24.4648 21.0522 28.142C20.2554 28.6901 19.5335 28.9573 18.8835 28.9433C18.171 28.9279 16.796 28.5395 15.7741 28.2076C14.5241 27.8004 13.5272 27.5851 13.6147 26.8936C13.6585 26.5336 14.1554 26.1651 15.1022 25.7886Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_743_50"
          x1="2006.05"
          y1="6"
          x2="2006.05"
          y2="4006"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
