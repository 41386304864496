import { useLang } from "../../../../contexts/LangContext";

import Card from "./Card";
import PeoplePrivate from "../../../../people/PeoplePrivate";
import PeoplePublic from "../../../../people/PeoplePublic";

import "./Cards.css";

export default function Cards() {
  const { currentLangId, langData } = useLang();

  return (
    <div className="tables-cards">
      <Card
        text={langData?.tablesPublicText[currentLangId]}
        title={langData?.tablesPublicTitle[currentLangId]}
        icon={<PeoplePublic />}
      />
      <Card
        text={langData?.tablesPrivateText[currentLangId]}
        title={langData?.tablesPrivateTitle[currentLangId]}
        icon={<PeoplePrivate />}
      />
    </div>
  );
}
