import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";

import "./Title.css";

export default function Title() {
  const { currentLangId, langData, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <AnimatePresence mode="wait" key="hero-title-animation">
      <motion.h1
        className="hero-title amatic-sc-bold"
        key={`hero-title-${langsHtmlCodes[currentLangId]}`}
        variants={textVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: animDuration }}>
        <span className="hero-title-text font-white">
          {langData?.heroTitle[currentLangId]}
        </span>
      </motion.h1>
    </AnimatePresence>
  );
}
