import coinRpss from "./coinRpss.webp";

export default function CoinRpss() {
  return (
    <img
      className="coin-rpss"
      src={coinRpss}
      alt="..."
      style={{ display: "block" }}
    />
  );
}
