import { createContext, useContext, useState, useEffect } from "react";

export const WidthContext = createContext(null);
export const useWidth = () => useContext(WidthContext);

const mobileWidth = 640;

export function WidthProvider({ children }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.clientWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const parallaxScrolled = () => {
      const scrolled = Math.round(window.scrollY * 0.1);
      const parallax = document.querySelector("#parallax");
      parallax.style.backgroundPositionY = `${-scrolled}px`;
    };

    window.addEventListener("scroll", parallaxScrolled);

    return () => {
      window.removeEventListener("scroll", parallaxScrolled);
    };
  }, []);

  const value = { windowWidth, mobileWidth };

  return (
    <WidthContext.Provider value={value}>{children}</WidthContext.Provider>
  );
}
