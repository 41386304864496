export default function FrameLL() {
  return (
    <svg
      className="frame-ll"
      width="58"
      height="204"
      viewBox="0 0 58 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_615_352)">
        <path
          d="M38.6529 19C43.0082 7.68235 58.9779 -1.67647 66.4183 -4.94118L70.2301 -8.20588C86.6534 -9.92892 122.93 -11.6882 136.649 -4.94118C153.798 3.49265 186.191 -4.94118 201.979 -1.67647C214.61 0.935293 231.923 -11.4706 239 -18L0 -8.75V204C3.44799 191.123 10.6706 162.865 11.9772 152.853C13.6105 140.338 21.7759 85.3823 21.2315 66.3382C20.687 47.2941 33.2087 33.1471 38.6529 19Z"
          fill="#F5BE38"
        />
        <path
          d="M0 -23H308C288.784 -19.9077 248.816 -13.6138 242.667 -13.1773C234.98 -12.6316 190.5 -5 168 -8.81163C150.137 -11.8378 108.483 -10.8672 93.5 -4C81.5 1.5 68.5654 -1.71745 43.9216 -1.71745C28.4621 -1.71745 19.2157 20.1108 17.0196 22.8393C14.8235 25.5679 7.68627 62.1302 7.68627 65.9501C7.68627 69.7701 9.88235 113.972 7.68627 127.069C5.92941 137.547 1.83007 162.722 0 174V-23Z"
          fill="#A85D13"
        />
      </g>
      <defs>
        <clipPath id="clip0_615_352">
          <rect width="58" height="204" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
