import { createContext, useContext, useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  getCountFromServer,
  getAggregateFromServer,
  sum,
} from "firebase/firestore";
import { db, collections } from "../firebase/init";

const paymentKey = "payment";
const bonusBalanceKey = "bonusBalance";

export const StatisticsContext = createContext(null);
export const useStatistics = () => useContext(StatisticsContext);

export function StatisticsProvider({ children }) {
  const [playersCount, setPlayersCount] = useState(0);
  const [paymentCountG, setPaymentCountG] = useState(0);
  const [paymentCountS, setPaymentCountS] = useState(0);

  useEffect(() => {
    const q = query(collection(db, collections.users));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added" || change.type === "removed") {
          const snapshot = await getCountFromServer(q);
          setPlayersCount(snapshot.data().count);
        }
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const q = query(collection(db, collections.feeDetails));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added" || change.type === "removed") {
          const snapshot = await getAggregateFromServer(q, {
            totalPayments: sum(paymentKey),
          });
          setPaymentCountG(snapshot.data().totalPayments);
        }
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const q = query(collection(db, collections.users));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(async () => {
        const snapshot = await getAggregateFromServer(q, {
          totalBalances: sum(bonusBalanceKey),
        });
        setPaymentCountS(snapshot.data().totalBalances);
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const value = { playersCount, paymentCountG, paymentCountS };

  return (
    <StatisticsContext.Provider value={value}>
      {children}
    </StatisticsContext.Provider>
  );
}
