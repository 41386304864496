import { useLang } from "../../../contexts/LangContext";

import SectionTitle from "../../sectionTitle/SectionTitle";
import Paragraph from "./paragraph/Paragraph";
import Cards from "./card/Cards";

import "./Statistics.css";

export default function Statistics() {
  const { currentLangId, langData } = useLang();

  return (
    <section className="statistics">
      <SectionTitle innerText={langData?.statisticsTitle[currentLangId]} />
      <Paragraph />
      <Cards />
    </section>
  );
}
