import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";

import "./Card.css";

export default function Card({ label, hands }) {
  const { currentLangId, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  return (
    <div className="combinations-card-wrap">
      <div className="combinations-card">{hands}</div>
      <AnimatePresence mode="wait" key="combinations-card-animation">
        <motion.p
          className="combinations-card-label font-white montserrat-medium"
          key={`combinations-card-label-${langsHtmlCodes[currentLangId]}`}
          variants={textVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: animDuration }}>
          {label}
        </motion.p>
      </AnimatePresence>
    </div>
  );
}
