import coinRpsg from "./coinRpsg.webp";

export default function CoinRpsg() {
  return (
    <img
      className="coin-rpsg"
      src={coinRpsg}
      alt="..."
      style={{ display: "block" }}
    />
  );
}
