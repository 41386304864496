import { useLang } from "../../../../contexts/LangContext";

import Card from "./Card";
import HandCombPaperRive from "../../../../rive/HandCombPaperRive";
import HandCombRockRive from "../../../../rive/HandCombRockRive";
import HandCombScissorsRive from "../../../../rive/HandCombScissorsRive";

import "./Card.css";

export default function Cards() {
  const { currentLangId, langData } = useLang();

  return (
    <div className="combinations-cards">
      <Card
        label={langData?.combinationsScissorsAndPaperLabel[currentLangId]}
        hands={<HandCombScissorsRive />}
      />
      <Card
        label={langData?.combinationsScissorsAndRockLabel[currentLangId]}
        hands={<HandCombRockRive />}
      />
      <Card
        label={langData?.combinationsRockAndPaperLabel[currentLangId]}
        hands={<HandCombPaperRive />}
      />
    </div>
  );
}
