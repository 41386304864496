import { useWidth } from "../../../../contexts/WidthContext";

import TopBar from "./topBar/TopBar";
import Title from "./title/Title";
import Cards from "./cards/Cards";
import Accordion from "./accordion/Accordion";

import "./GameTypeSelect.css";

const mobileWidth = 640; // px

export default function GameTypeSelect({ icon, selectData }) {
  const { windowWidth } = useWidth();

  return (
    <div className="game-type-select-wrap">
      <TopBar icon={icon} />
      <div className="game-type-select-body">
        <Title innerText={selectData?.title} />
        {windowWidth > mobileWidth ? (
          <Cards selectData={selectData} />
        ) : (
          <Accordion selectData={selectData} />
        )}
      </div>
    </div>
  );
}
