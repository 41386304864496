import "./TopBar.css";

export default function TopBar({ icon }) {
  return (
    <div className="game-type-select-top-bar">
      <div className="game-type-select-hand-bg"></div>
      {icon}
    </div>
  );
}
