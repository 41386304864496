export default function FrameLT() {
  return (
    <svg
      className="frame-lt"
      width="308"
      height="23"
      viewBox="0 0 308 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_612_330)">
        <path
          d="M38.6529 42C43.0082 30.6824 58.9779 21.3235 66.4183 18.0588L70.2301 14.7941C86.6534 13.0711 122.93 11.3118 136.649 18.0588C153.798 26.4926 186.191 18.0588 201.979 21.3235C214.61 23.9353 231.923 11.5294 239 5L0 14.25V227C3.44799 214.123 10.6706 185.865 11.9772 175.853C13.6105 163.338 21.7759 108.382 21.2315 89.3382C20.687 70.2941 33.2087 56.1471 38.6529 42Z"
          fill="#F5BE38"
        />
        <path
          d="M0 0H308C288.784 3.09234 248.816 9.38615 242.667 9.82272C234.98 10.3684 190.5 18 168 14.1884C150.137 11.1622 108.483 12.1328 93.5 19C81.5 24.5 68.5654 21.2825 43.9216 21.2825C28.4621 21.2825 19.2157 43.1108 17.0196 45.8393C14.8235 48.5679 7.68627 85.1302 7.68627 88.9501C7.68627 92.7701 9.88235 136.972 7.68627 150.069C5.92941 160.547 1.83007 185.722 0 197V0Z"
          fill="#A85D13"
        />
      </g>
      <defs>
        <clipPath id="clip0_612_330">
          <rect width="308" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
