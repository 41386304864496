import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";
import { coinTypes } from "../Coins";

import "./Title.css";

const gClass = "font-yellow";
const sClass = "font-silver";

export default function Title({ type }) {
  const { currentLangId, langData, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();

  let titleText, titleClass;

  switch (true) {
    case type === coinTypes.gold:
      titleText = langData?.coinsRpsgTitle[currentLangId];
      titleClass = gClass;
      break;

    case type === coinTypes.silver:
      titleText = langData?.coinsRpssTitle[currentLangId];
      titleClass = sClass;
      break;

    default:
      break;
  }

  return (
    <AnimatePresence mode="wait" key="coin-title-animation">
      <motion.h5
        className={`coin-title montserrat-bold ${titleClass}`}
        key={`coin-title-${langsHtmlCodes[currentLangId]}`}
        variants={textVariants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: animDuration }}>
        {titleText.toUpperCase()}
      </motion.h5>
    </AnimatePresence>
  );
}
