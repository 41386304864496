import { createContext, useContext } from "react";

export const AnimationContext = createContext(null);
export const useAnimation = () => useContext(AnimationContext);

const textVariants = {
  enter: { opacity: 0.5 },
  center: { opacity: 1 },
  exit: { opacity: 0.5 },
};

const selectListVariants = {
  enter: { opacity: 0 },
  center: { opacity: 1 },
  exit: { opacity: 0 },
};

const pagesVariants = {
  enter: { opacity: 0 },
  center: { opacity: 1 },
  exit: { opacity: 0 },
};

const animDuration = 0.125;

export function AnimationProvider({ children }) {
  const value = {
    textVariants,
    selectListVariants,
    animDuration,
    pagesVariants,
  };

  return (
    <AnimationContext.Provider value={value}>
      {children}
    </AnimationContext.Provider>
  );
}
