import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../contexts/LangContext";
import { useAnimation } from "../../../contexts/AnimationContext";

import "./List.css";

const basicClass = "lang-select-list-btn lang-select-font montserrat-regular";

export default function List({ toggleSelect, isSelectOpen }) {
  const { langsFullLabels, setCurrentLangId, langsHtmlCodes, currentLangId } =
    useLang();
  const { selectListVariants, animDuration } = useAnimation();

  return (
    <AnimatePresence mode="wait" key="select-list-animation" >
      {isSelectOpen && (
        <motion.div
          className="lang-select-list"
          key={`list-${isSelectOpen ? "visible" : "hidden"}`}
          variants={selectListVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: animDuration }}>
          {langsFullLabels.map((langFullLabel, index) => (
            <button
              key={langsHtmlCodes[index]}
              type="button"
              className={
                currentLangId === index
                  ? `${basicClass} current font-black`
                  : `${basicClass} font-white`
              }
              onClick={() => {
                toggleSelect(false);
                setCurrentLangId(index);
              }}>
              {langFullLabel}
            </button>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
