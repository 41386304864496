import { AnimatePresence, motion } from "framer-motion";
import { useAnimation } from "../../../../contexts/AnimationContext";
import { useLang } from "../../../../contexts/LangContext";
import { coinTypes } from "../Coins";

import IconOrangeRhombus from "../../../../icons/IconOrangeRhombus";
import IconWhiteRhombus from "../../../../icons/IconWhiteRhombus";
import IconWhiteInfo from "../../../../icons/IconWhiteInfo";

import "./Items.css";

export default function Items({ type, coinItems }) {
  const { textVariants, animDuration } = useAnimation();
  const { currentLangId, langsHtmlCodes } = useLang();

  let itemIcon;

  switch (true) {
    case type === coinTypes.gold:
      itemIcon = <IconOrangeRhombus />;
      break;

    case type === coinTypes.silver:
      itemIcon = <IconWhiteRhombus />;
      break;

    default:
      return;
  }

  return (
    <ul className="coin-items">
      {coinItems.map((itemData, i) => (
        <li key={`coin-item-${i}`} className="coin-item">
          {itemData?.isInfo ? <IconWhiteInfo /> : itemIcon}
          <AnimatePresence mode="wait" key="coin-item-text-animation">
            <motion.span
              className="coin-item-text font-white montserrat-regular"
              key={`coin-item-text-${langsHtmlCodes[currentLangId]}-${i}`}
              variants={textVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: animDuration }}>
              {itemData?.isInfo ? (
                <>
                  {itemData?.before + " "}
                  <span className="font-yellow montserrat-semi-bold">
                    {itemData?.colored + " "}
                  </span>
                  {itemData?.after}
                </>
              ) : (
                itemData?.text
              )}
            </motion.span>
          </AnimatePresence>
        </li>
      ))}
    </ul>
  );
}
