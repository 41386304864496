import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { config } from "./config.js";

const app = initializeApp(config);
const db = getFirestore(app);
const collections = {
  users: "users",
  feeDetails: "feeDetails",
};

export { app, db, collections };
