import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "../../../../contexts/LangContext";
import { useAnimation } from "../../../../contexts/AnimationContext";
import { useWidth } from "../../../../contexts/WidthContext";

import "./Items.css";

const mobWidth = 640; // px

export default function Item({ itemData }) {
  const { currentLangId, langsHtmlCodes } = useLang();
  const { textVariants, animDuration } = useAnimation();
  const { windowWidth } = useWidth();

  return (
    <li className="about-item">
      {windowWidth > mobWidth ? (
        <>
          {itemData?.icon}
          <div className="about-item-topography">
            <AnimatePresence mode="wait" key="about-item-title-animation">
              <motion.h6
                className="about-item-title montserrat-semi-bold font-yellow"
                key={`about-item-title-${langsHtmlCodes[currentLangId]}`}
                variants={textVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: animDuration }}>
                {itemData?.title}
              </motion.h6>
            </AnimatePresence>
            <AnimatePresence mode="wait" key="about-item-paragraph-animation">
              <motion.p
                className="about-item-paragraph montserrat-regular font-white"
                key={`about-item-paragraph-${langsHtmlCodes[currentLangId]}`}
                variants={textVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: animDuration }}>
                {itemData?.paragraph}
              </motion.p>
            </AnimatePresence>
          </div>
        </>
      ) : (
        <>
          <div className="about-item-row">
            {itemData?.icon}
            <AnimatePresence mode="wait" key="about-item-title-animation">
              <motion.h6
                className="about-item-title montserrat-semi-bold font-yellow"
                key={`about-item-title-${langsHtmlCodes[currentLangId]}`}
                variants={textVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: animDuration }}>
                {itemData?.title}
              </motion.h6>
            </AnimatePresence>
          </div>
          <AnimatePresence mode="wait" key="about-item-paragraph-animation">
            <motion.p
              className="about-item-paragraph montserrat-regular font-white"
              key={`about-item-paragraph-${langsHtmlCodes[currentLangId]}`}
              variants={textVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: animDuration }}>
              {itemData?.paragraph}
            </motion.p>
          </AnimatePresence>
        </>
      )}
    </li>
  );
}
