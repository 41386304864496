import { useLang } from "../../../contexts/LangContext";

import SectionTitle from "../../sectionTitle/SectionTitle";
import Cards from "./card/Cards";

import "./Combinations.css";

export default function Combinations() {
  const { currentLangId, langData } = useLang();

  return (
    <section className="combinations">
      <SectionTitle innerText={langData?.combinationsTitle[currentLangId]} />
      <Cards />
    </section>
  );
}
